import React from 'react'

const CtaSection = () => (
  <div className="bg-gray-100 w-full py-16 border-b">
    <div className="w-11/12 max-w-3xl mx-auto">
      <h2 className='text-4xl md:text-5xl text-site-lightblue text-center font-bold mb-8'>Already have an IT department?</h2>
      <p className='text-lg text-gray-800 text-center max-w-2xl mx-auto'>We’ll work with your existing IT to add resources, insight, and efficiency to save you money. Give us a call today for a free estimate.</p>
      <div className='flex justify-center'>
        <a href="tel:9495430234" className="bg-red-600 hover:bg-red-700 text-white font-display px-8 py-4 text-xl text-center font-semibold tracking-wide rounded mt-8 shadow-lg">
          (949) 543-0234
        </a>
      </div>
    </div>
  </div>
)

export default CtaSection